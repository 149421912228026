import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { FEATURE_FLAG, FeatureFlagService } from '@quipex/shared/data';
import {
    HasPermissionDirective,
    QpxTypography,
} from '@quipex/shared/directives';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'qpx-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    imports: [
        RouterLink,
        RouterLinkActive,
        RouterOutlet,
        HasPermissionDirective,
        QpxTypography,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminComponent {
    private readonly featureFlagService = inject(FeatureFlagService);

    protected readonly isContractorManagementFeatureEnabled =
        this.featureFlagService.isFeatureEnabled(
            FEATURE_FLAG.ContractorManagement
        );

    protected isProdEnvironment = environment.isProd;
}
