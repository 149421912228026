import { Route } from '@angular/router';
import {
    accessGuard,
    canDeactivateGuard,
    organisationsGuard,
    unitOwnersGuard,
    usersGuard,
} from '@quipex/feature/guards';
import { AdminComponent } from './admin.component';
import { ListUsersComponent } from './list-users/list-users.component';
import { ManageUserComponent } from './manage-user/manage-user.component';

export const adminRoutes: Route[] = [
    {
        path: '',
        component: AdminComponent,
        children: [
            {
                path: 'organizations',
                loadChildren: () =>
                    import('@quipex/feature/organizations').then(
                        (mod) => mod.orgsRoutes
                    ),
                canActivate: [organisationsGuard],
            },
            {
                path: 'contractors',
                loadChildren: () =>
                    // CLARIFY:MC - contemplate a renaming of the feature module later
                    import('@quipex/feature/access').then(
                        (mod) => mod.accessRoutes
                    ),
                canActivate: [accessGuard],
            },
            {
                path: 'unit-owners',
                loadChildren: () =>
                    import('@quipex/feature/unit-owners').then(
                        (mod) => mod.unitOwnersRoutes
                    ),
                canActivate: [unitOwnersGuard],
            },
            {
                path: 'users',
                component: ListUsersComponent,
                canActivate: [usersGuard],
            },
            {
                path: 'users/:id',
                component: ManageUserComponent,
                canActivate: [usersGuard],
                canDeactivate: [canDeactivateGuard],
            },
            { path: '', redirectTo: '/admin/users', pathMatch: 'full' },
        ],
    },
];
