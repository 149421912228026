import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    OnInit,
    inject,
    input,
    signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    FormControl,
    FormGroup,
    FormsModule,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CanDeactivateType } from '@quipex/feature/guards';
import { LoaderComponent } from '@quipex/shared/components';
import {
    DialogService,
    IGrantedBuilding,
    IRolesWithPermission,
    IUpdateUser,
    PermissionApiService,
    UserApiService,
    UserStore,
    IUserAccess,
} from '@quipex/shared/data';
import { AddressPipe } from '@quipex/shared/pipes';
import {
    BehaviorSubject,
    Subject,
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    forkJoin,
    of,
    switchMap,
} from 'rxjs';

@Component({
    selector: 'qpx-manage-user',
    templateUrl: './manage-user.component.html',
    styleUrls: ['./manage-user.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSnackBarModule,
        LoaderComponent,
        AddressPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageUserComponent implements OnInit {
    id = input.required<string>();
    isLoading = signal(true);
    isSaving = signal(false);
    inactiveUser = signal(false);
    isQuipexAdmin = signal(false);

    buildings$ = new BehaviorSubject<IGrantedBuilding[]>([]);
    buildingsAll: IGrantedBuilding[] = [];
    form!: FormGroup;
    roles!: IRolesWithPermission[];
    user = signal<IUserAccess | null>(null);

    private _modelChanged = false;
    private _shouldRefreshUser!: boolean;

    get searchCtrl(): FormControl {
        return this.form?.controls['search'] as FormControl;
    }

    get toggleCtrl(): FormControl {
        return this.form?.controls['toggle'] as FormControl;
    }

    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly formBuilder = inject(NonNullableFormBuilder);
    private readonly dialogService = inject(DialogService);
    private readonly snackBar = inject(MatSnackBar);
    private readonly userService = inject(UserApiService);
    private readonly userStore = inject(UserStore);
    private readonly permissionService = inject(PermissionApiService);

    constructor() {
        this._shouldRefreshUser = this.router.lastSuccessfulNavigation?.extras
            .state?.['refresh']
            ? true
            : false;
    }

    ngOnInit(): void {
        this.dialogService.requestCancel$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.dialogService.closeDialog());

        this.getData();
    }

    onBuildingModelChange(): void {
        this._modelChanged = true;

        if (this.toggleCtrl.value) {
            this.toggleCtrl.setValue(false);
        } else {
            const hasUnselected = this.buildings$.value.some(
                (item) => !item.isGranted
            );
            if (!hasUnselected) {
                this.toggleCtrl.setValue(true);
            } else if (this.toggleCtrl.value) {
                this.toggleCtrl.setValue(false);
            }
        }
    }

    canDeactivate(): CanDeactivateType {
        if (this._modelChanged || this.form?.dirty) {
            const confirmationDialogRef =
                this.dialogService.openConfirmationDialog({
                    title: 'Discard changes?',
                    text1: 'Are you sure you want to exit? Your changes will not be saved.',
                    text2: '',
                    type: undefined,
                    confirmButtonType: undefined,
                    confirmButtonText: 'Yes',
                    cancelButtonText: '',
                    showCloseIcon: true,
                    cancelButtonVisible: false,
                });

            const modalConfirmation = new Subject<boolean>();
            confirmationDialogRef
                .afterClosed()
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        if (result === 'confirm') {
                            this.dialogService.closeDialog();
                            return modalConfirmation.next(true);
                        } else {
                            return modalConfirmation.next(false);
                        }
                    },
                });
            return modalConfirmation;
        } else {
            return true;
        }
    }

    navigateToUsersScreen(): void {
        this.router.navigate(['/admin/users']);
    }

    onToggleAll(): void {
        if (this.toggleCtrl.value) {
            this.buildings$
                .getValue()
                .forEach((building) => (building.isGranted = true));
        } else {
            this.buildings$
                .getValue()
                .forEach((building) => (building.isGranted = false));
        }
        this._modelChanged = true;
    }

    changeUserStatus(): void {
        const promptText = this.inactiveUser()
            ? 'Re-enabling this user will grant their access to Quipex'
            : 'Disabling this user will remove their access to Quipex';

        const confirmationDialogRef = this.dialogService.openConfirmationDialog(
            {
                title: 'Are you sure?',
                text1: `${promptText}`,
                text2: '',
                type: undefined,
                confirmButtonType: undefined,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                showCloseIcon: true,
                cancelButtonVisible: true,
            }
        );

        confirmationDialogRef
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    if (result === 'confirm') {
                        this.isSaving.set(true);
                        if (this.inactiveUser()) {
                            this.userService
                                .reactiveateUser(this.id())
                                .pipe(finalize(() => this.isSaving.set(false)))
                                .subscribe({
                                    next: () => {
                                        this.inactiveUser.set(false);
                                        this.enablePage();
                                    },
                                });
                        } else {
                            this.userService
                                .inactivateUser(this.id())
                                .pipe(finalize(() => this.isSaving.set(false)))
                                .subscribe({
                                    next: () => {
                                        this.inactiveUser.set(true);
                                        this.disablePage();
                                        this._modelChanged = false;
                                        this.form.markAsPristine();
                                    },
                                });
                        }
                    }
                },
            });
    }

    saveChanges(): void {
        const selectedBuildingIds = this.buildingsAll
            .filter((building) => building.isGranted)
            .map((b) => b.id);

        const updatedUser: IUpdateUser = {
            userId: this.id(),
            name: this.form.controls['name'].value,
            email: this.form.controls['email'].value,
            roleId: this.form.controls['role'].value?.roleId,
            isActive: true,
            grantBuildingIds: selectedBuildingIds,
        };

        this.isSaving.set(true);
        this.userService
            .updateUserAccess(updatedUser)
            .pipe(finalize(() => this.isSaving.set(false)))
            .subscribe({
                next: () => {
                    this.snackBar.open(
                        'User updated successfully',
                        'check_circle',
                        {
                            horizontalPosition: 'center',
                            verticalPosition: 'top',
                            panelClass: 'success',
                            duration: 1500,
                        }
                    );

                    const user = this.user();
                    user!.name = updatedUser.name;
                    user!.email = updatedUser.email;
                    user!.role =
                        this.roles.find(
                            (role) => role.roleId === updatedUser.roleId
                        )?.name ?? '';
                    this.user.set(user);

                    this._modelChanged = false;
                    this.form.markAsPristine();

                    if (this._shouldRefreshUser) {
                        this.userStore.dispatch();
                    }

                    this.navigateToUsersScreen();
                },
            });
    }

    private configureSearch(): void {
        this.searchCtrl.valueChanges
            .pipe(
                debounceTime(350),
                distinctUntilChanged(),
                filter((value) => {
                    if (typeof value === 'string' && value.trim() === '') {
                        this.buildings$.next([...this.buildingsAll]);
                    }
                    return typeof value === 'string' && value.trim() !== '';
                }),
                switchMap((value) => {
                    const filteredBuildings = this.buildingsAll.filter(
                        (building) =>
                            building.buildingName
                                .toLowerCase()
                                .indexOf(value.toLowerCase()) > -1
                    );
                    return of(filteredBuildings);
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((response) => {
                this.buildings$.next(response);
            });
    }

    private configureForm(user: IUserAccess): FormGroup {
        const form = this.formBuilder.group({});
        form.addControl(
            'name',
            new FormControl(user?.name ?? '', [Validators.required])
        );
        form.addControl(
            'email',
            new FormControl(user?.email ?? '', [
                Validators.required,
                Validators.email,
            ])
        );
        form.addControl(
            'role',
            new FormControl(
                user?.roleId
                    ? this.roles?.find(
                          (role: IRolesWithPermission) =>
                              role.roleId === user.roleId
                      )
                    : null,
                [Validators.required]
            )
        );

        form.addControl('search', new FormControl(''));
        form.addControl('toggle', new FormControl(false));

        form.valueChanges
            .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: () => {
                    this.dialogService.setCanConfirm(this.form.valid);
                },
            });

        return form;
    }

    private getData(): void {
        const roles$ = this.permissionService.getRolesWithPermissions();
        const user$ = this.userService.geAccesstUser(this.id());

        forkJoin([roles$, user$])
            .pipe(finalize(() => this.isLoading.set(false)))
            .subscribe({
                next: ([roles, user]) => {
                    this.roles = roles;
                    user.role =
                        roles.find((role) => role.roleId === user.roleId)
                            ?.name ?? '';
                    this.user.set(user);

                    this.buildingsAll = user.grantBuildings;
                    this.buildings$.next(user.grantBuildings);

                    this.form = this.configureForm(user);

                    const hasUnselected = this.buildings$.value.some(
                        (item) => !item.isGranted
                    );
                    if (!hasUnselected) {
                        this.toggleCtrl.setValue(true);
                    }

                    this.inactiveUser.set(!user.isActive);
                    if (this.inactiveUser()) {
                        this.disablePage();
                    }

                    this.configureSearch();
                    this.cdr.markForCheck();
                },
            });
    }

    private disablePage(): void {
        this.form.controls['name'].disable();
        this.form.controls['email'].disable();
        this.form.controls['role'].disable();
        this.form.controls['toggle'].disable();

        this.buildingsAll.forEach((building) => (building.isGranted = false));
        this.buildings$.next([...this.buildingsAll]);
    }

    private enablePage(): void {
        this.form.controls['name'].enable();
        this.form.controls['email'].enable();
        this.form.controls['role'].enable();
        this.form.controls['toggle'].enable();
    }
}
