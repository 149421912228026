<div class="header">
    <h1 qpxHeading class="title">Admin</h1>
    <div class="nav-section">
        <ul class="flex wrap align-center nav-links">
            <li *hasPermission="['update_users']">
                <a
                    class="nav-tab"
                    data-testid="userLink"
                    [routerLink]="['/admin', 'users']"
                    routerLinkActive="active"
                >
                    Users
                </a>
            </li>
            <li *hasPermission="['update_orgs']">
                <a
                    class="nav-tab"
                    data-testid="orgLink"
                    [routerLink]="['/admin', 'organizations']"
                    routerLinkActive="active"
                >
                    Organisations
                </a>
            </li>
            @if (isContractorManagementFeatureEnabled) {
                <li *hasPermission="['read_access']">
                    <a
                        class="nav-tab"
                        data-testid="contractorLink"
                        [routerLink]="['/admin', 'contractors']"
                        routerLinkActive="active"
                    >
                        Contractors
                    </a>
                </li>
            }
            <li *hasPermission="['read_unitowner']">
                <a
                    class="nav-tab"
                    data-testid="unitOwnersLink"
                    [routerLink]="['/admin', 'unit-owners']"
                    routerLinkActive="active"
                >
                    Unit Owners
                </a>
            </li>
        </ul>
    </div>
</div>

<router-outlet class="hidden" />
